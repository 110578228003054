import Axios from "axios"
import getDomainURL from "utils/api"
import Cookies from "js-cookie"
import { clearItem } from "utils/tokenStore"
import FileSaver from "file-saver"

const defaultMessage =
  "You are disconnnected from the Internet, please reconnect to continue. If problem persists, please contact the system admin."
  
export const Get = async (url, response, error, load) => {
  load(true)
  try {
    let token = Cookies.get("EON_ACCESS_TOKEN")
    Axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
    const res = await Axios.get(`${getDomainURL()}${url}`)
    response(res.data)
  } catch (err) {
    handleRequestError(err, error)
  } finally {
    load(false)
  }
}

export const Post = async (url, data, response, error, load) => {
  load(true)
  try {
    let token = Cookies.get("EON_ACCESS_TOKEN")
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
    const res = await Axios.post(`${getDomainURL()}${url}`, data)
    response(res.data)
  } catch (err) {
    handleRequestError(err, error)
  } finally {
    load(false)
  }
}

export const Put = async (url, data, response, error, load) => {
  load(true)
  try {
    let token = Cookies.get("EON_ACCESS_TOKEN")
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
    const res = await Axios.put(`${getDomainURL()}${url}`, data)
    response(res.data)
  } catch (err) {
    handleRequestError(err, error)
  } finally {
    load(false)
  }
}

export const Delete = async (url, response, error, load) => {
  load(true)
  try {
    let token = Cookies.get("EON_ACCESS_TOKEN")
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
    const res = await Axios.delete(`${getDomainURL()}${url}`)
    response(res.data)
  } catch (err) {
    handleRequestError(err, error)
  } finally {
    load(false)
  }
}

export const GetPreview = async (url, savedFilename, response, error, load) => {
  load(true)
  try {
    let token = Cookies.get("EON_ACCESS_TOKEN")
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
    Axios.defaults.responseType = "blob"
    const res = await Axios.get(`${getDomainURL()}${url}`)
    load(false)
    if (res.status === 200) {
      const resultBlob = new Blob([res.data])
      FileSaver.saveAs(resultBlob, savedFilename)
      response(resultBlob)
    }
  } catch (err) {
    handleRequestError(err, error)
  } finally {
    load(false)
  }
}

export const GetFile = async (url, savedFilename, response, error, load) => {
  load(true)
  try {
    let token = Cookies.get("EON_ACCESS_TOKEN")
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
    Axios.defaults.responseType = "blob"
    const res = await Axios.post(`${getDomainURL()}${url}`)
    load(false)
    if (res.status === 200) {
      const resultBlob = new Blob([res.data])
      FileSaver.saveAs(resultBlob, savedFilename)
      response(resultBlob)
    }
  } catch (err) {
    handleRequestError(err, error)
  } finally {
    load(false)
  }
}

export const handleRequestError = (err, errorFunction) => {
  if (err && err.response) {
    if (err.response.status === 401) {
      clearItem("EON_ACCESS_TOKEN")
      window.location.reload()
      errorFunction(err.response.status)
    } else if (err.response.status === 500) {
      errorFunction(
        "Server encountered issues. Please contact your system admin for assistance."
      )
    } else {
      errorFunction(err.response.data)
    }
  } else if (err.response) {
    errorFunction(err.response.data)
  } else {
    errorFunction({message: defaultMessage})
  }
}