import React, { Component } from "react";
import { Button } from "reactstrap";
import { clearItem } from "utils/tokenStore";
import { RiLogoutBoxLine } from "react-icons/ri";
import { connect } from "react-redux"
import { compose } from "redux"

import { setUserProfile } from "reducers/profile";
class Userbox extends Component {
  render = () => {
    return (
      <div className="header-btn-lg pr-0 ml-0" style={{ paddingLeft: "1rem" }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left d-flex align-items-center">
              <Button
                className="btn-pill btn-shadow btn-shine"
                color="focus"
                onClick={() => {
                  Promise.all([
                    clearItem("EON_ACCESS_TOKEN"),
                    this.props.setUserProfile({}),
                  ]).then(() => {
                    window.location.reload()
                  })
                }}
              >
                <RiLogoutBoxLine
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default compose(
  connect(null, { setUserProfile }),
)(Userbox);
