import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import Header from "components/Header";
import LoadingOverlay from "components/Loading"
import TemplateContainerMain from "components/Template";
import TemplateSidemenu from "components/SideMenu/Sidemenu";

import { routes } from "./router";
import { setPath } from 'actions/path'
import { getItem } from "utils/tokenStore";
import { getProfile } from "actions/profile";

const PrefixURL = "/dashboard";

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
);

class Dashboard extends Component {
  componentDidMount = () => {
    if( !getItem( 'EON_ACCESS_TOKEN' )) {
      this.props.history.push( '/login' );
    } else if( this.props.data.ProfileReducer.profile?.role !== 'admin' ) {
      this.props.setPath([{ label: "Admin" }])
    } else if( this.props.data.ProfileReducer.profile?.role !== 'bank admin' ) {
      this.props.setPath([{ label: "Bank Admin" }])
    }
  };

  render = () => {
    return (
      <TemplateContainerMain>
        <Header pathData={this.props.setPath}/>
        <TemplateSidemenu />
        <div className="app-main">
          <div className="app-main__outer">
            <div className="app-main__inner">
              {
                routes.map((route, index) => (
                  <SuspenseWrapper key={index}>
                    <Route
                      exact={route.exact}
                      path={`${PrefixURL}${route.path}`}
                      render={(props) => route.render(props)} />
                  </SuspenseWrapper>
                ))
              }
            </div>
          </div>
        </div>
      </TemplateContainerMain>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps, {
  setPath,
  getProfile,
  withRouter,
})(Dashboard);
