import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import _ from "lodash";

import { setEnableMobileMenu } from "reducers/ThemeOptions";

import { ModuleNav, AdminNav, AccountNav } from "./dataMainNav";
import './index.scss'

class VerticalNavWrapper extends Component {
  state = { menus: [] };

  componentDidMount() {
    if (this.props.data.ProfileReducer.profile.role === "super admin") {
      this.setState({ menus: AdminNav });
    } else {
      this.setState({ menus: ModuleNav });
    }
  }

  render = () => {
    return (
      <>
        {/* <h2 className="app-sidebar__heading">Module</h2> */}
        <MetisMenu
          content={this.state.menus}
          onSelected={() => 
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />

        {/* <h2 className="app-sidebar__heading">My Account</h2>
        <MetisMenu
          content={AccountNav}
          onSelected={(e) => {
            if( e.target.innerText === "Logout" ) {
              console.log("Logout");
            }
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        /> */}
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenum,
  data: state,
});

export default connect(mapStateToProps, {
  setEnableMobileMenu,
})(VerticalNavWrapper);
