import React, { lazy } from "react";

const LoanApplication = lazy(() => import("./Application"));
const DashboardHome = lazy(() => import("./Home"));
const UserManagement = lazy(() => import("../Administration/UserManagement"));
const StaffManagement = lazy(() => import("../Administration/StaffID"));

export const routes = [
  {
    path: "/user_management",
    exact: true,
    render: (props) => <UserManagement {...props} />,
  },
  {
    path: "/staff_id_management",
    exact: true,
    render: (props) => <StaffManagement {...props} />,
  },
  {
    path: "/application",
    exact: true,
    render: (props) => <LoanApplication {...props} />,
  },
  {
    path: "/home",
    exact: true,
    render: (props) => <DashboardHome {...props} />,
  }
];